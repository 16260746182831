import React from "react";
import gradients from "../../constructor/umas/Actividades/ActividadesUtils/ActividadesUtils";

const CabeceraRecorada = ({
  texto,
  imagen,
  tipoImagen,
  tipoCabecera,
  onClick,
}) => {
  const foundGradient = gradients?.find((el) =>
    tipoCabecera.includes(el.name)
  )?.class;

  const gradient = foundGradient ? foundGradient : gradients[0].class;

  const hasCustomImage = tipoCabecera.includes("http");

  return (
    <div
      onClick={onClick ? onClick : undefined}
      className={`w-full flex flex-row items-center  overflow-hidden rounded-lg scale-[1.015] mb-10 ${gradient}  overflow-hidden min-h-[25rem] visor`}
      style={{
        backgroundImage: hasCustomImage
          ? `url(${tipoCabecera.split(" - ")[1]})`
          : "",
        backgroundSize: `60% 100%`,
      }}
    >
      {/* Sección de Texto */}
      <div className="w-1/2 text-left mb-0 h-full flex items-center justify-center relative scale-75 lg:scale-100">
        <h2
          className="font-bold mb-4 h-full z-10 flex items-center justify-center flex-col !text-white pl-4 pl-4"
          dangerouslySetInnerHTML={{ __html: texto }}
        ></h2>
        <div></div>
      </div>
      {/* Sección de Imagen */}
      <div className="w-1/2 flex justify-end relative h-full">
        <div className="triangular-mask overflow-hidden relative w-full">
          <img
            src={imagen}
            alt="Descripción de la imagen"
            className={`cabecera-recortada-img absolute w-full max-w-full h-auto min-h-[15rem] !object-cover ${
              tipoImagen === "CORTADA"
                ? "object-cover"
                : tipoImagen === "COMPLETA"
                ? "object-contain"
                : "object-fill"
            }`}
          />
        </div>
      </div>
    </div>
  );
};

export default CabeceraRecorada;
