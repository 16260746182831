import React from "react";
import { ReactComponent as MisComprasCircleIcon } from "../../../../assets/icons/misComprasCircleIcon.svg";
import { ReactComponent as ViewIcon } from "../../../../assets/icons/view.svg";
import { ReactComponent as FacturacionCircleIcon } from '../../../../assets/icons/FacturacionCircleIcon.svg';
import { useNavigate } from "react-router-dom";
import { formatCurrency } from "../../../utils/productoUtils";
import { formatDate } from "../../../utils/dateFunctions";
import { METODOS_DE_PAGO } from "../../../utils/misComprasUtils";

const MisComprasRow = ({ compraInfo, abreviatura, handleCreateBill }) => {
  const { _id, fechaCreacion, total, metodoDePago, estatus, esPagado, factura } = compraInfo;

  const navigate = useNavigate();

  const billOnTime = () => {
    let diasTranscurridos = new Date() - new Date(compraInfo.fechaCreacion);
    diasTranscurridos /= 1000 * 60 * 60 * 24;
    return diasTranscurridos <= 30;
  }

  return (
    <tr className="p-2 text-left bg-white">
      <td className="border-2 rounded-l-lg border-r-0 font-sans">
        <MisComprasCircleIcon className="mx-auto" />
      </td>
      <td className="border-y-2 font-sans">{_id}</td>
      <td className="border-y-2 font-sans">{formatDate(new Date(fechaCreacion))}</td>
      <td className="border-y-2 font-sans text-right pr-4">{formatCurrency(total)}</td>
      <td className="border-y-2 font-sans">{METODOS_DE_PAGO[metodoDePago]}</td>
      <td
        className={`border-y-2 font-sans ${
          estatus === "CANCELADA" || estatus === "PAGO_FALLIDO"
            ? "text-[#EB0000]"
            : esPagado
            ? "text-[#17A300]"
            : "text-[#B89A00]"
        }`}
      >
        {estatus === "CANCELADA" || estatus === "PAGO_FALLIDO" ? "CANCELADO" : esPagado ? "COMPLETADO" : "PENDIENTE"}
      </td>
      <td
        className={`border-y-2 font-sans ${
          estatus === "ENTREGADO"
            ? "text-nimbusDark"
            : estatus === "COMPLETADO"
            ? "text-[#17A300]"
            : estatus === "PREPARANDO"
            ? "text-[#B89A00]"
            : "text-[#EB0000]"
        }`}
      >
        {estatus === "PAGO_FALLIDO" ? "CANCELADO" : estatus}
      </td>
      <td className="border-2 rounded-r-md border-l-0 min-h-[52px] font-sans flex py-[10px] gap-[10px]">
        {!factura && billOnTime() && esPagado && (
          <button 
            className="flex items-center gap-2 px-1 py-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={() => handleCreateBill(compraInfo._id)}
          >
            <FacturacionCircleIcon className='mx-auto w-[26px] h-[25px]' color='white' />
            Generar factura
          </button>
        )}

        <button 
          className="flex items-center gap-2 px-1 py-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          onClick={() =>
            navigate(`/marketplace/misCompras/${_id}`, {
              state: {
                compraInfo: compraInfo,
                abreviatura: abreviatura,
              },
            })
          }
        >
          <ViewIcon
            className="mx-auto cursor-pointer"
            title="Ver compra"
          />

          Detalles
        </button>
      </td>
    </tr>
  );
};

export default MisComprasRow;
