import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { isAscending } from "./ActividadesUtils/ActividadesUtils";
import LightboxWrapper from "./ActividadesUtils/Lightbox/LightboxWrapper";
import Lightbox from "./ActividadesUtils/Lightbox/Lightbox";
import ImagenValidada from "./ActividadesUtils/RecursosValidados/ImagenValidada";
import SimplePlayer from "./ActividadesUtils/SimplePlayer";
import VideoValidado from "./ActividadesUtils/RecursosValidados/VideoValidado";
import DefaultVideo from "../../../../assets/icons/video_bg.png";

function AnagramWord({ word, addPoint, evaluable, containerWidth, actValue, enableScore, removePoint }) {
  const [letters, setLetters] = useState([]);
  const [dragValue, setDragValue] = useState(false);
  const [lightboxImage, setLightboxImage] = useState('')
  const [isVideo, setIsVideo] = useState(false)

  const handleReorder = (lettersArray) =>{
    function reorderArray(array) {
      let currentIndex = array.length,
        randomIndex;

      while (currentIndex != 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex]
        ];
      }

      return array;
    }

    const reorderedArr = reorderArray(lettersArray)
    const reorderedArrOrder = reorderedArr.map((el)=>(el.id))
    if(isAscending(reorderedArrOrder) === false || reorderedArr.length <= 1){
      setLetters(reorderedArr)
    }else{
      handleReorder(reorderedArr)
    }
  }

  //crea un nuevo arreglo al cambiar de palabra
  useEffect(() => {
    setDragValue(false);

    const wordLetters = () =>{
      if(actValue === 1){
        return word.split('')
      }else if(actValue === 2){
        return word.split(' ')
      }else{
        //En caso de no encontrar texto se usa la url de la imagen como referencia
        return word
          .map((el) => ({
            ...el,
            texto: el.texto
              ? el.texto
              : el.imagen
              ? el.imagen
              : el.audio
              ? el.audio
              : el.video
              ? el.video
              : "",
          }))
          .filter((el) => el.texto !== "");
      }
    }
    const lettersArray = wordLetters().map((item, index) => {
      return {
        texto: item.texto ? item.texto : item,
        imagen: item.imagen ? item.imagen : null,
        audio: item.audio ? item.audio : null,
        video: item.video ? item.video : null,
        id: `${index}`,
        orden: index,
      };
    });

    if(evaluable){
      handleReorder(lettersArray)
    }else{
      setLetters(lettersArray)
    }
  }, [evaluable]);

  //crea una nueva array en base al nuevo orden
  function handleOnDragEnd(result) {
    enableScore()
    if (!result.destination) return;

    const items = Array.from(letters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setLetters(items);
  }

  //revisa si la palabra es correcta
  useEffect(() => {
    const currentStringArray = letters.map((item) => {
      return item.texto;
    });

    const currentString = () =>{
      //Anagrama
      if(actValue === 1){
        return currentStringArray.join("")
      //Ordenar palabras
      }else if(actValue === 2){
        return currentStringArray.join(' ')
      }else{
        return currentStringArray.join(' ')
      }
    }

    const correctString = () => {
      return word
    }

    const isTheWordCorrect = () => {
      if (actValue === 3) {
        if(letters.length === 0 )return
        const hasErrors = letters.some((el, i) => el.orden !== i);
        if (hasErrors) return false;
        else return true;
      } else {
        if (currentString() === correctString()) return true;
        else return false;
      }
    };

    if (isTheWordCorrect() && !dragValue) {
      addPoint()
      setDragValue(true)
      //if(actValue !== 3) setDragValue(!dragValue); //No se desactiva el drag al tener una palabra correcta para arrastrar pasos
    }else if(!isTheWordCorrect() && dragValue){
      removePoint()
      setDragValue(false)
    }
  }, [letters]);

  return (
    <>
      <Lightbox lightboxValue={lightboxImage} closeLightbox={()=>setLightboxImage('')} isVideo={isVideo}/>
      <div className={`${actValue === 2 ? 'w-full mx-auto' : actValue === 3 ? 'w-fit max-w-[625px] mx-auto' : 'inline-block'} ${actValue === 2 && containerWidth <= 768 ? 'max-w-[190px]' : ''}`}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="letters" direction={actValue === 3 || actValue === 2 && containerWidth <= 768 ? 'vertical' : 'horizontal'}>
            {(provided) => (
              <ul
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={` characters-container flex border border-[#dcdcdc] my-2 bg-white rounded p-0 ${actValue === 1 ? 'w-min lg:mx-4 lg:my-3 mr-4' : ''} ${actValue === 2 ? 'w-full overflow-auto mx-auto max-w-fit' : ''} ${actValue === 3  || actValue === 2 && containerWidth <= 768 ? 'flex-col w-full mx-auto p-3 md:p- 6' : ''}`}
              >
                {letters.map((item, index) => {
                  return (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                      //isDragDisabled={dragValue}
                    >
                      {(provided) => (
                        <>
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {actValue === 1 ?
                            <div
                              className={'border border-[#dcdcdc] flex items-center justify-center p-[5px] md:p-0 md:m-1 md:rounded-md bg-white transition-all w-8 aspect-square md:text-2xl md:w-12 md:flex md:justify-center md:items-center'}
                              style={{ maxWidth:(containerWidth / letters.length) - 8}}
                            >
                              {item.texto}
                            </div>
                            :<div
                                className={'transition-all bg-white border border-[#dcdcdc] text-left flex items-center m-1 md:m-2 rounded-md md:text-2xl px-4 py-2 wit-fit'}
                                //style={dragValue ? { background:'#4a8f67', color:"white"} : {background : ''} }
                            >
                              {actValue === 3 ? 
                              <>
                                <div className="mr-2">{index + 1}.</div>
                                {item?.imagen ? 
                                <LightboxWrapper handleClick={()=>setLightboxImage(item.imagen)} styles={'bottom-2 right-[1rem]'}>
                                  <ImagenValidada src={item.imagen} alt="" className="min-w-[5rem] w-[5rem] h-[5rem] object-contain rounded-lg" />
                                </LightboxWrapper>
                                :null}
                                {item?.audio ? <SimplePlayer size={'text-2xl !mx-0 !p-3 bg-white !w-[4.5rem]'} audio={typeof item.audio === 'string' ? item.audio : URL.createObjectURL(item.audio)}/> : ''}
                                {item?.video && 
                                  <LightboxWrapper 
                                    handleClick={()=>{
                                      setIsVideo(true)
                                      setLightboxImage(item.video)
                                    }} 
                                    styles={'bottom-2 right-[1rem]'}>
                                    <img
                                      alt=""
                                      src={DefaultVideo}
                                      className="min-w-[5rem] w-[5rem] h-[5rem] object-contain rounded-lg"
                                    />
                                  </LightboxWrapper>
                                }
                              </>
                              :null}
                              <div className="ml-2 md:ml-4 overflow-auto">
                                 {/* En caso de no encontrar texto se usa la url del recurso como referencia */}
                                 {item.texto !== item.imagen &&
                                    item.texto !== item.video &&
                                    item.texto !== item.audio &&
                                    item?.texto}
                              </div>
                            </div>}
                          </li>
                        </>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
}

export default AnagramWord;
