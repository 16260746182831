function InputSelect({
    value,
    onBlur,
    onChange,
    name,
    id,
    disabled = false,
    label,
    error='',
    options=[], 
    topLabel = false, 
    styles="", 
    placeholder, 
    disableFirstOption = false, 
    defaultValue = "Todos", 
    disabledStyles = false, 
    customLabel = "",
    stylesContenedor = "",
    itemSelectedId
}) {
    return(
        <div className={`grid gap-y-2 ${stylesContenedor}`}>
            {!topLabel && <p className="font-semibold text-base text-[#0D0C22] truncate">{label}</p>}
            <div className="relative">
                <select
                    className={`peer border-2 h-[40px] focus:outline-none rounded-lg p-2 w-80 lg:w-full truncate pr-6 ${styles} ${error !== '' ? 'border-red-400' : 'border-gray-300'} ${disabledStyles && "bg-gray-200"}`}
                    value={itemSelectedId || value}
                    onBlur={onBlur}
                    onChange={onChange}
                    name={name}
                    id={id}
                    disabled={disabled || itemSelectedId}
                >   
                    {topLabel? <option value="">{defaultValue}</option> : <option value="" disabled={disableFirstOption}>{customLabel !== "" ? customLabel : `Seleccione ${placeholder ? placeholder :label?.toLowerCase()}`}</option>}
                    {options.map(option=>{
                        return <option key={option._id} value={option._id}>{option.nombre}</option>
                    })}
                </select>
                {topLabel &&
                    <label className={`absolute left-4 -top-1.5 text-blue-400 text-xs z-100 bg-backgroundColor transition-all peer-focus:absolute peer-focus:left-4 peer-focus:-top-1.5 peer-focus:text-blue-400 peer-focus:text-xs px-1`} htmlFor={name}>{label}</label> 
                }
                <p className="font-thin text-xs text-red-400">{error}</p>
            </div>
        </div>
    )
}

export default InputSelect;