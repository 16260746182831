import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeHigh, faPause } from "@fortawesome/free-solid-svg-icons";
import ReactAudioPlayer from "react-audio-player";
import { Trans } from "react-i18next";

function ComposedPlayer({ size, audio }) {
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [audioValidado, setAudioValidado] = useState(true);
  const audioRef = useRef(null);

  const toggleAudio = () => {
    if (audioPlaying) {
      audioRef.current.audioEl.current.pause(); // Pause the audio
      setAudioPlaying(false);
    } else {
      audioRef.current.audioEl.current.play(); // Play the audio
      setAudioPlaying(true);
    }
  };

  return (
    <>
      <ReactAudioPlayer
        ref={audioRef}
        src={audio}
        controls={false} // Hide default controls
        onEnded={() => setAudioPlaying(false)}
        className="absolute -top-[9999px] -left-[9999px] opacity-0"
        onError={() => setAudioValidado(false)}
      />
      {audioValidado ? (
        <div
          className={`flex items-center justify-center pointer-events-auto border aspect-square p-8 rounded-full cursor-pointer hover:brightness-110 ${size}`}
          onClick={toggleAudio}
        >
          <FontAwesomeIcon
            color={"#1c7ccc"}
            icon={audioPlaying ? faPause : faVolumeHigh}
          />
        </div>
      ) : (
        <div className="text-center font-bold text-nimbusLight !text-base p-2 max-w-[9rem] bg-white rounded-lg mx-auto m-4">
          <Trans i18nKey={"resourceNotFound"}></Trans>
        </div>
      )}
    </>
  );
}

export default ComposedPlayer;
