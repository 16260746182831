import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlay } from '@fortawesome/free-solid-svg-icons';
import CaratulaRelacionar from '../../../../../assets/icons/icons-bloques/caratulaRelacionar.png'
import CaratulaRompecabezas from '../../../../../assets/icons/icons-bloques/caratulaRompecabezas.png'
import CaratulaPreguntas from '../../../../../assets/icons/icons-bloques/caratulaPreguntas.png'
import CaratulaOrdenar from '../../../../../assets/icons/icons-bloques/caratulaOrdenar.png'
import CaratulaArrastrar from '../../../../../assets/icons/icons-bloques/caratulaArrastrar.png'
import CaratulaMemorama from '../../../../../assets/icons/icons-bloques/caratulaMemorama.png'
import CaratulaSopa from '../../../../../assets/icons/icons-bloques/caratulaSopa.png'
import CaratulaCrucigrama from '../../../../../assets/icons/icons-bloques/caratulaCrucigrama.png'
import CaratulaHotspot from '../../../../../assets/icons/icons-bloques/caratulaHotspot.png'
import CaratulaDibujo from '../../../../../assets/icons/icons-bloques/caratulaDibujo.jpg'
import CaratulaHablar from '../../../../../assets/icons/icons-bloques/caratulaHablar.png'
import CaratulaLeer from '../../../../../assets/icons/icons-bloques/caratulaLeer.png'
import CaratulaEscuchaEscribe from '../../../../../assets/icons/icons-bloques/caratulaEscuchaEscribe.png'
import { Trans } from 'react-i18next';


function PantallaInicio({title,description, isDone, start, tipo, lang}) {
  
  const chooseBg =
  tipo === 'rompecabezas' ? CaratulaRompecabezas :
  tipo === 'relacionar' ? CaratulaRelacionar :
  tipo === 'preguntas' ? CaratulaPreguntas : 
  tipo === 'arrastrar' ? CaratulaArrastrar : 
  tipo === 'ordenar' ? CaratulaOrdenar : 
  tipo === 'memorama' ? CaratulaMemorama : 
  tipo === 'sopa' ? CaratulaSopa : 
  tipo === 'crucigrama' ? CaratulaCrucigrama : 
  tipo === 'hotspot' || tipo === 'hotspotMultiple' || tipo === 'encontrarDiferencias'  ? CaratulaHotspot : 
  tipo === 'dibujo' ? CaratulaDibujo : 
  tipo === 'lecturaPalabras' || tipo === 'lecturaPronunciacion' || tipo === 'lecturaVelocidad' ? CaratulaHablar : 
  tipo === 'leeYEscucha' ? CaratulaLeer : 
  tipo === 'escuchaEscribe' ? CaratulaEscuchaEscribe : 
  ''

  return (
      <div 
        className='relative w-full aspect-[23/9] border rounded-2xl p-6 flex items-center justify-center bg-white text-[#606581] text-center bg-cover md:bg-[length:100%_100%]'
        style={{  backgroundImage: `url(${chooseBg})`}}
      >
        <div className='w-full max-w-[35rem] z-[1]'>
          <div className="font-bold text-3xl md:text-4xl mb-3">
            <Trans i18nKey={
              tipo === 'rompecabezas' ? 'rompecabezasTitle' :
              tipo === 'relacionar' ? 'relacionarTitle' :
              tipo === 'preguntas' ? 'preguntasTitle' : 
              tipo === 'preguntasAbiertas' ? 'preguntasAbiertasTitle' : 
              tipo === 'arrastrar' ? 'arrastrarTitle' : 
              tipo === 'ordenar' ? 'ordenarTitle' : 
              tipo === 'memorama' ? 'memoramaTitle' : 
              tipo === 'sopa' ? 'sopaTitle' : 
              tipo === 'crucigrama' ? 'crucigramaTitle' : 
              tipo === 'hotspot' ? 'hotspotTitle' : 
              tipo === 'hotspotMultiple' ? 'hotspotMultipleTitle' : 
              tipo === 'encontrarDiferencias' ? 'encontrarTitle' : 
              tipo === 'dibujo' ? 'dibujarTitle' : 
              tipo === 'lecturaPalabras' ? 'lecturaPalabrasTitle' : 
              tipo === 'lecturaPronunciacion' ? 'lecturaPronunciacionTitle' : 
              tipo === 'lecturaVelocidad' ? 'lecturaVelocidadTitle' : 
              tipo === 'leeYEscucha' ? 'escuchaTitle' : 
              tipo === 'escuchaEscribe' ? 'dictadosTitle' : 
              ''
            }>
              {title}
            </Trans>
          </div>
          <div className="text-base md:text-xl w-full max-w-[768px] mb-4">
            <Trans i18nKey={
              tipo === 'rompecabezas' ? 'rompecabezasDesc' :
              tipo === 'relacionar' ? 'relacionarDesc' :
              tipo === 'preguntas' ? 'preguntasDesc' : 
              tipo === 'preguntasAbiertas' ? 'preguntasAbiertasDesc' : 
              tipo === 'arrastrar' ? 'arrastrarDesc' : 
              tipo === 'ordenar' ? 'ordenarDesc' : 
              tipo === 'memorama' ? 'memoramaDesc' : 
              tipo === 'sopa' ? 'sopaDesc' : 
              tipo === 'crucigrama' ? 'crucigramaDesc' : 
              tipo === 'hotspot' ? 'hotspotDesc' : 
              tipo === 'hotspotMultiple' ? 'hotspotMultipleDesc' : 
              tipo === 'encontrarDiferencias' ? 'encontrarDesc' : 
              tipo === 'dibujo' ? 'dibujarDesc' : 
              tipo === 'lecturaPalabras' ? 'lecturaPalabrasDesc' : 
              tipo === 'lecturaPronunciacion' ? 'lecturaPronunciacionDesc' : 
              tipo === 'lecturaVelocidad' ? 'lecturaVelocidadDesc' : 
              tipo === 'leeYEscucha' ? 'escuchaDesc' : 
              tipo === 'escuchaEscribe' ? 'dictadosDesc' : 
              ''
            }>
              {description}
            </Trans>
          </div>
          <div className='mb-6'>
          <div 
            className='border-2 border-[#1cb9fcab] rounded-2xl px-4 w-fit mx-auto py-4 my-5 md:my-[1.5rem] hover:brightness-105 transition cursor-pointer'
            onClick={()=>start(true)}
            >
            <div className="flex mb-3 mx-auto rounded-full aspect-square w-10 h-10 md:w-[4rem] md:h-[4rem] text-white items-center justify-center bg-[#1CB9FC]">
                <FontAwesomeIcon className="text-xl md:text-3xl cursor-pointer rounded-full" color={'#ffffff'} icon={faPlay}></FontAwesomeIcon>
            </div>
            <span className="md:text-2xl font-bold text-[#1CB9FC] min-w-[80px] md:min-w-[130px] block">{lang === 'en' ? 'Play' : <Trans i18nKey={'comenzarButton'}></Trans>}</span>
          </div>
          <div>
              {isDone && isDone.intento > 0 && 
              <>
                <p className='text-sm mb-3'><Trans i18nKey="attemptsNumber"></Trans> {isDone.intento}</p>
                <p className='text-sm mb-3'><Trans i18nKey="highestScore"></Trans> {isDone ? isDone.puntuacionTop : 0}</p>
                <p className='text-sm mb-3'><Trans i18nKey="lastScore"></Trans> {isDone ? isDone.puntuacionUltimoIntento : 0}</p>
              </>}
            </div>
          </div>
        </div>
          <img
            src={
              tipo === 'rompecabezas' ? CaratulaRompecabezas :
              tipo === 'relacionar' ? CaratulaRelacionar :
              tipo === 'preguntas' || tipo === 'preguntasAbiertas' ? CaratulaPreguntas : 
              tipo === 'arrastrar' ? CaratulaArrastrar : 
              tipo === 'ordenar' ? CaratulaOrdenar : 
              tipo === 'memorama' ? CaratulaMemorama : 
              tipo === 'sopa' ? CaratulaSopa : 
              tipo === 'crucigrama' ? CaratulaCrucigrama : 
              tipo === 'hotspot' || tipo === 'hotspotMultiple' || tipo === 'encontrarDiferencias'  ? CaratulaHotspot : 
              tipo === 'dibujo' ? CaratulaDibujo : 
              tipo === 'lecturaPalabras' || tipo === 'lecturaPronunciacion' || tipo === 'lecturaVelocidad' ? CaratulaHablar : 
              tipo === 'leeYEscucha' ? CaratulaLeer : 
              tipo === 'escuchaEscribe' ? CaratulaEscuchaEscribe : 
              ''
            }
            alt=""
            className="w-full h-full absolute object-cover md:object-fill rounded-2xl"
          />
      </div>
  )
}

export default PantallaInicio