import React, { useCallback, useEffect, useState } from "react";
import InputText from "../../../fragments/Inputs/InputText";
import InputSelect from "../../../fragments/Inputs/InputSelect";
import InputFileCard from "../../../fragments/Inputs/InputFileCard";
import InputNumber from "../../../fragments/Inputs/InputNumber";
import ProductoImage from "../../../../assets/img/producto.png";
import {
  OPCIONES_SI_NO,
  TIPOS_CONFIGURACION_PRODUCTO,
  TIPOS_DE_CATEGORIA,
  TIPOS_DE_PRODUCTO,
  TIPOS_ESTATUS,
} from "../../../utils/productoUtils";
import { getNivelAcademico, getNivelesAcademico } from "../../../services/api";
import { generarNuevaVariable } from "./fragments/GenerarVariable";
import { ReactComponent as InfoIcon } from "../../../../assets/icons/info.svg";
import { ReactComponent as ExternalLink } from "../../../../assets/icons/externalLink.svg";
import InputMoneda from "../../../fragments/Inputs/InputMoneda";
import { useParams } from "react-router-dom";
import { MESSAGES } from "../../../utils/messagesUtils";

const ProductoInfoForm = ({
  producto,
  errors,
  handleChange,
  setProducto,
  ver,
  editar,
  deleteImage,
  setVariables,
  tipoDeMoneda,
  variables,
  carrusel,
}) => {
  let {
    _id,
    imagen,
    nombreProducto,
    precioVenta,
    categoria,
    estatus,
    nombreCategoria,
    claveArticulo,
    claveUnidad,
    impuestos,
    porcentajeImpuestos,
    marca,
    tipoProducto,
    nivelAcademico,
    gradoAcademico,
    notificar,
    correoNotificacion,
    esDestacado,
    configuracionProducto,
    variaciones,
  } = producto;
  const [nivelesAcademicos, setNivelesAcademicos] = useState([]);
  const [gradosAcademicos, setGradosAcademicos] = useState([]);
  const params = useParams();
  
  const getData = useCallback(async () => {
    const nivelesAcademicosResponse = await getNivelesAcademico();
    setNivelesAcademicos(nivelesAcademicosResponse.data);
  }, []);

  useEffect(() => {
    if (nivelAcademico !== "") {
      getNivelAcademico(nivelAcademico).then((valores) => {
        setGradosAcademicos(valores.data.gradosAcademicos);
      });
      setProducto({ ...producto });
    }
  }, [nivelAcademico]);

  useEffect(() => {
    if (categoria !== "otro") {
      let categoriaAEncontrar = TIPOS_DE_CATEGORIA.find((categoria) => categoria._id === producto.categoria);
      setProducto({ ...producto, nombreCategoria: "", tipoProducto: categoriaAEncontrar?.tipo ?? "" });
    }
  }, [categoria]);

  useEffect(() => {
    if (configuracionProducto === "PRODUCTO_VARIABLE" && variables.length === 0) {
      let nuevaVariable = generarNuevaVariable();
      setVariables([nuevaVariable]);
      setProducto({ ...producto, estatus: false });
    } else if (configuracionProducto !== "PRODUCTO_VARIABLE") {
      setVariables([]);
    }
  }, [configuracionProducto]);

  useEffect(() => {
    if (!notificar) {
      setProducto({ ...producto, correoNotificacion: "" });
    }
  }, [notificar]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className="mx-0 lg:mx-24">
      <h2 className="text-black text-lg mb-6">Información del producto</h2>
      <div className="flex flex-col items-center lg:items-start lg:flex-row">
        <div className="mb-6 lg:mr-16 flex flex-col md:grid md:grid-cols-2 lg:flex lg:flex-col gap-6 w-full lg:w-fit">
          <InputFileCard
            name={"imagen"}
            id={"imagen"}
            styles="mt-0 mb-10 col-span-2 mx-auto lg:mx-0"
            imageStyles="rounded-none border-none !w-auto"
            img={imagen ? imagen : producto?.carrusel ? producto?.carrusel[0]?.imagen : "" }
            bgImage={ProductoImage}
            deleteImage={deleteImage}
            onChange={(e) => handleChange(e, "imagen")}
            disabled={true}
            allowMultipleExtensions
            accept=".jpg, .png"
          />
          <div className="w-full lg:w-64 relative">
            <InputText
              label={"Clave del artículo"}
              name={"claveArticulo"}
              placeholder={"Ingrese la clave del artículo"}
              styles="!w-full"
              value={claveArticulo}
              onChange={(e) => handleChange(e, "claveArticulo")}
              error={errors["claveArticulo"]}
              disabled={ver}
              onlyNumbers
              maxLength={10}
              minLength={8}
            />
            <InfoIcon
              className="absolute top-1 left-[160px] cursor-pointer"
              title="Debe introducir una clave acorde al SAT."
            />

            <a
              className="absolute top-[1px] left-[190px] cursor-pointer underline text-black hover:text-blue-600"
              href="https://sifo.com.mx/buscador_de_claves_de_productos_y_servicios_de_el_sat_cfdi_33_para_facturar.php" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <ExternalLink
                style={{ stroke: '#224386'}}
              />
            </a>
          </div>
          <div className="w-full lg:w-64 relative">
            <InputText
              label={"Clave de la unidad"}
              name={"claveUnidad"}
              placeholder={"Ingrese la clave de la unidad"}
              styles="!w-full"
              value={claveUnidad}
              onChange={(e) => handleChange(e, "claveUnidad")}
              error={errors["claveUnidad"]}
              disabled={ver}
              upperCase
              maxLength={10}
            />
            <InfoIcon
              className="absolute top-1 left-[160px] cursor-pointer"
              title="Debe introducir una clave acorde al SAT."
            />
            <a
              className="absolute top-[1px] left-[190px] cursor-pointer underline text-black hover:text-blue-600"
              href="http://pys.sat.gob.mx/PyS/catUnidades.aspx" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <ExternalLink
                style={{ stroke: '#224386'}}
              />
            </a>
          </div>
          <div className="w-full lg:w-64">
            <InputSelect
              name={"impuestos"}
              label={"¿El producto lleva impuesto?"}
              placeholder={"si lleva impuesto"}
              styles="!w-full"
              value={impuestos}
              onChange={(e) => handleChange(e, "impuestos")}
              error={errors["impuestos"]}
              options={OPCIONES_SI_NO}
              disableFirstOption
              disabled={ver}
            />
          </div>
          {impuestos &&
            <div className="w-full lg:w-64">
              <InputText
                label={"Porcentaje del impuesto (%)"}
                name={"porcentajeImpuestos"}
                placeholder={"Ingrese un valor"}
                styles="!w-full"
                value={porcentajeImpuestos}
                onChange={(e) => handleChange(e, "porcentajeImpuestos")}
                error={errors["porcentajeImpuestos"]}
                disabled={ver}
              />
            </div>
          }
          <div className="w-full lg:w-64 relative">
            <InputMoneda
              name={"precioVenta"}
              label={"Precio de venta neto"}
              extraInfoLines={tipoDeMoneda}
              placeholder={"Ingrese el precio de venta"}
              styles="!w-full"
              wrapperStyles={`${tipoDeMoneda.length > 1 && "-translate-y-2"}`}
              value={precioVenta ?? ""}
              onChange={(e) => handleChange(e, "precioVenta")}
              error={errors["precioVenta"]}
              disabled={ver}
            />
            <InfoIcon
              className="absolute top-1 left-60 cursor-pointer"
              title="Debe introducir el precio de venta neto incluyendo el impuesto."
            />
          </div>       
          {/* OCULTADO HASTA LLEGAR AL SPRINT DE INVENTARIO */}
          {/* {configuracionProducto !== "PRODUCTO_DIGITAL" && (
            <div className="w-full lg:w-64">
              <InputText
                name={"stock"}
                label={"Cantidad en stock"}
                placeholder={"0"}
                styles="!w-full"
                disabled
                disabledStyles
              />
            </div>
          )} */}
        </div>
        <div className="flex flex-col md:grid md:grid-cols-2 lg:flex lg:flex-col xl:grid xl:grid-cols-2 md:items-start lg:items-stretch xl:items-start gap-3 sm:gap-6 flex-1 w-full lg:w-fit">
          <div className="relative">
            <InputSelect
              name={"configuracionProducto"}
              label={"Configuración del producto"}
              placeholder={"el tipo de configuración"}
              styles="!w-full"
              value={configuracionProducto ?? ""}
              onChange={(e) => handleChange(e, "configuracionProducto")}
              error={errors["configuracionProducto"]}
              options={TIPOS_CONFIGURACION_PRODUCTO}
              disableFirstOption
              disabled={ver || editar}
            />
            <InfoIcon
              className="absolute top-1 left-56 cursor-pointer"
              title={MESSAGES.MESSAGES_AVISO_CONFIGURACION_PRODUCTO}
            />
          </div>
          <InputText
            label={"ID del producto"}
            disabled
            disabledStyles={!ver}
            styles="!w-full"
            value={_id ?? ""}
          />
          <div className="col-span-2">
            <InputText
              name={"nombreProducto"}
              label={"Nombre del producto"}
              placeholder={"Ingrese el nombre del producto"}
              styles="!w-full"
              value={nombreProducto}
              onChange={(e) => handleChange(e, "nombreProducto")}
              error={errors["nombreProducto"]}
              disabled={ver}
            />
          </div>
          <InputSelect
            name={"categoria"}
            label={"Categoría del producto"}
            placeholder={"la categoría"}
            styles="!w-full"
            value={categoria}
            onChange={(e) => handleChange(e, "categoria")}
            error={errors["categoria"]}
            options={TIPOS_DE_CATEGORIA}
            disableFirstOption
            disabled={ver || editar}
          />
          <InputSelect
            name={"estatus"}
            label={"Estatus"}
            placeholder={"el estatus"}
            styles="!w-full"
            value={estatus}
            onChange={(e) => handleChange(e, "estatus")}
            error={errors["estatus"]}
            options={TIPOS_ESTATUS}
            disableFirstOption
            disabled={
              ver ||
              (configuracionProducto === "PRODUCTO_VARIABLE"
                ? (variaciones && variaciones?.length === 0) || (!ver && !editar)
                : false)
            }
          />
          {categoria === "otro" && (
            <div className="col-span-2">
              <InputText
                name={"nombreCategoria"}
                label={"Nombre de la categoría"}
                placeholder={"Ingrese el nombre de la categoría"}
                styles="!w-full"
                value={nombreCategoria ?? ""}
                onChange={(e) => handleChange(e, "nombreCategoria")}
                error={errors["nombreCategoria"]}
                disabled={ver}
              />
            </div>
          )}
          <InputText
            name={"marca"}
            label={"Marca"}
            placeholder={"Ingrese la marca"}
            styles="!w-full"
            value={marca}
            onChange={(e) => handleChange(e, "marca")}
            error={errors["marca"]}
            disabled={ver || editar}
          />
          <InputSelect
            name={"tipoProducto"}
            label={"Tipo de producto"}
            placeholder={"el tipo de producto"}
            styles="!w-full"
            value={tipoProducto}
            onChange={(e) => handleChange(e, "tipoProducto")}
            error={errors["tipoProducto"]}
            options={TIPOS_DE_PRODUCTO}
            disableFirstOption
            disabled={categoria !== "otro" || ver}
            disabledStyles={!ver && categoria !== "otro"}
          />
          <InputSelect
            name={"nivelAcademico"}
            label={"Nivel educativo"}
            placeholder={"el nivel educativo"}
            styles="!w-full"
            value={nivelAcademico}
            onChange={(e) => handleChange(e, "nivelAcademico")}
            error={errors["nivelAcademico"]}
            options={nivelesAcademicos}
            disableFirstOption
            disabled={ver}
          />
          <InputSelect
            name={"gradoAcademico"}
            label={"Grado académico"}
            placeholder={"el grado académico"}
            styles="!w-full"
            value={gradoAcademico}
            onChange={(e) => handleChange(e, "gradoAcademico")}
            error={errors["gradoAcademico"]}
            options={gradosAcademicos}
            disableFirstOption
            disabled={ver}
          />
          <div className="relative">
            <InputSelect
              name={"notificar"}
              label={"¿Se debe notificar?"}
              placeholder={"si se debe notificar"}
              styles="!w-full"
              value={notificar}
              onChange={(e) => handleChange(e, "notificar")}
              error={errors["notificar"]}
              options={OPCIONES_SI_NO}
              disableFirstOption
              disabled={ver}
            />
            <InfoIcon
              className="absolute top-1 left-40 cursor-pointer"
              title="Al seleccionar la opción de SÍ, cada vez que este producto sea vendido, se le notificará a su correo."
            />
          </div>
          <div
            className={`${
              notificar ? "visible block" : "hidden md:block md:invisible lg:hidden lg:visible xl:block xl:invisible"
            } relative`}
          >
            <InputText
              name={"correoNotificacion"}
              label={"Correo de notificación adicional"}
              placeholder={ver ? "" : "Ingrese el correo electrónico adicional"}
              styles="!w-full"
              value={correoNotificacion ?? ""}
              onChange={(e) => handleChange(e, "correoNotificacion")}
              error={errors["correoNotificacion"]}
              disabled={ver}
            />
            <InfoIcon
              className="absolute top-1 left-64 cursor-pointer"
              title="Debe introducir un correo de notificación adicional solo si desea que se le notifique en un correo diferente al que tiene registrado en la plataforma, al cual, se notifica por defecto."
            />
          </div>
          <div className="relative">
            <InputSelect
              name={"esDestacado"}
              label={"¿Es artículo destacado?"}
              placeholder={"si es artículo destacado"}
              styles="!w-full"
              value={esDestacado}
              onChange={(e) => handleChange(e, "esDestacado")}
              error={errors["esDestacado"]}
              options={OPCIONES_SI_NO}
              disableFirstOption
              disabled={ver}
            />
            <InfoIcon
              className="absolute top-1 left-[12.5rem] cursor-pointer"
              title="Al seleccionar la opción de SÍ, este producto se mostrará en la página principal de la tienda de los padres de familia del plantel, la cuál corresponde a los artículos destacados."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductoInfoForm;
