import { _delete, _get, _post, _put } from "../../http";

export const getEditoriales = () => _get('/editoriales');

export const getEditorialDetalle = (editorialId) => _get(`/editoriales/${editorialId}`);

export const getEditoresEditorial = (editorialId) => _get(`/editoriales/${editorialId}/editoresEditorial`);

export const getLibrosDistribucionesEditorial = (editorialId, distribucionId) => _get(`/editoriales/${editorialId}/libros/${distribucionId}`);

export const postEditorial = (body) => _post(`/editoriales`,body);

export const putEditorial = (editorialId, body) => _put(`/editoriales/${editorialId}`,body);

export const deleteEditorial = (editorialId) => _delete(`/editoriales/${editorialId}`);

export const publicarLibros = (editorialId, distribucionId, body) => _post(`/editoriales/${editorialId}/distribucion/${distribucionId}`, body);

export const publicarLibrosMasivo = (editorialId, body) => _post(`/editoriales/${editorialId}/publicacionMasiva/distribuciones`, body);

export const despublicarLibrosMasivo = (editorialId, body) => _post(`/editoriales/${editorialId}/despublicacionMasiva/distribuciones`, body);