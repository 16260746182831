import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPause,
  faPlay,
  faVolumeHigh,
  faVolumeMute,
  faGaugeHigh,
} from "@fortawesome/free-solid-svg-icons";

const AudioValidado = ({ src, fileName = "audio-file" }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false); // Estado para mute

  // Play/Pause functionality
  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  // Update the current time and duration
  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleLoadedMetadata = () => {
    setDuration(audioRef.current.duration);
  };

  // Handle progress bar changes (seek)
  const handleSeek = (e) => {
    const newTime = e.target.value;
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  // Handle volume change
  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    audioRef.current.volume = newVolume;
    setVolume(newVolume);
    setIsMuted(newVolume === "0"); // Sincroniza mute con el volumen
  };

  // Toggle mute
  const toggleMute = () => {
    if (isMuted) {
      audioRef.current.volume = volume;
    } else {
      audioRef.current.volume = 0;
    }
    setIsMuted(!isMuted);
  };

  // Handle playback speed change
  const handleSpeedChange = (e) => {
    const rate = parseFloat(e.target.value);
    audioRef.current.playbackRate = rate;
    setPlaybackRate(rate);
  };

  // Format time in mm:ss
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
  };

  return (
    <div className="flex flex-col sm:flex-row bg-[#d4edf6] text-nimbusLight p-4 rounded-[999px] w-full shadow-lg gap-4">
      <div className="flex items-center justify-center flex-1">
        {/* Play/Pause Button */}
        <div className="flex items-center gap-4">
          <button
            onClick={togglePlayPause}
            className="bg-[#01c3ff] text-white font-semibold w-8 h-8 sm:w-10 sm:h-10 flex items-center justify-center transition rounded-full hover:scale-105 mr-2"
          >
            <FontAwesomeIcon
              icon={isPlaying ? faPause : faPlay}
            ></FontAwesomeIcon>
          </button>
        </div>

        {/* Progress Bar */}
        <div className="flex items-center gap-2 w-full">
          <span className="text-sm">{formatTime(currentTime)}</span>
          <input
            type="range"
            min="0"
            max={duration}
            value={currentTime}
            onChange={handleSeek}
            className="w-full h-2 rounded-lg appearance-none cursor-pointer progress-bar"
            style={{
              background: `linear-gradient(to right, #01c3ff ${
                (currentTime / duration) * 100
              }%, #fff ${(currentTime / duration) * 100}%)`,
            }}
          />
          <span className="text-sm">{formatTime(duration)}</span>
        </div>
      </div>
      {/* Volume Control */}
      <div className=" flex items-center gap-2 relative ">
        <button
          onClick={toggleMute}
          className="bg-[#01c3ff] text-white font-semibold h-8 w-8 flex items-center justify-center transition rounded-full"
        >
          <FontAwesomeIcon
            icon={isMuted ? faVolumeMute : faVolumeHigh}
          ></FontAwesomeIcon>
        </button>
        <input
          id="volume"
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={volume}
          onChange={handleVolumeChange}
          className="w-32 h-2 cursor-pointer appearance-none rounded-lg progress-bar"
          style={{
            background: `linear-gradient(to right, #01c3ff ${
              volume * 100
            }%, #fff ${volume * 100}%)`,
          }}
        />
        <span className="text-sm">{Math.round(volume * 100)}%</span>
      </div>

      {/* Playback Speed */}
      <div className="flex items-center gap-2 relative md:left-4">
        <button className="bg-[#01c3ff] text-white font-semibold h-8 w-8 flex items-center justify-center transition rounded-full z-[2] pointer-events-none">
          <FontAwesomeIcon icon={faGaugeHigh}></FontAwesomeIcon>
        </button>
        <select
          id="playbackRate"
          value={playbackRate}
          onChange={handleSpeedChange}
          className="p-1 rounded-md border-none bg-transparent font-bold relative -left-[2.5rem] pl-[2.5rem] w-fit audio-select z-[1]"
          style={{
            background: "#d4edf6",
          }}
        >
          <option value="0.5">0.5x</option>
          <option value="1">1x</option>
          <option value="1.5">1.5x</option>
          <option value="2">2x</option>
        </select>
      </div>

      {/* Hidden Audio Element */}
      <audio
        ref={audioRef}
        src={src}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleLoadedMetadata}
      />
    </div>
  );
};

export default AudioValidado;
