const correosValidosRegex =
  /^(director_plantel|director_editorial|distribuidor|director_corporativo|administrador_operativo|padre|autor_editorial|autor_independiente|autor_escolar|editor_editorial|profesor|alumno|coordinador_academico|asesor)\d+@(marketplace|yopmail|bibliotecaDeMedios)\.com$/;

const correosConAcceso = [
  "super_admin@nimbus.com",
  "director_editorialSelene@yopmail.com",
  "directo_editorialVidal@yopmail.com",
  "director_editorialYesenia@yopmail.com",
  "distribuidor_Selene@yopmail.com",
  "distribuidor_Vidal@yopmail.com",
  "distribuidor_Yesenia1@yopmail.com",
  "director_CorporativoSelene@yopmail.com",
  "director_CorporativoVidal@yopmail.com",
  "director_corporativoYesenia@yopmail.com",
  "administrador_OperativoSelene@yopmail.com",
  "administrador_OperativoVidal@yopmail.com",
  "director_PlantelSelene@yopmail.com",
  "director_PlantelVidal@yopmail.com",
  "hola@directorEditorial.com",
  "hola@editorEditorial1.com",
  "hola@autorEditorial.com",
  "hola@autorIndependiente.com",
  "hola@autorEscolar.com",
  "hola@distribuidor.com",
  "hola@directorCorporativo.com",
  "hola@directorPlantel.com",
  "hola@administradorOperativo.com",
  "hola@padre.com",
  "dsad@aac.xaa",
  "pruebaeditorial3@prueba.com",
  "directoreditorial@prueba.com",
  "editorplantel@prueba.com",
  "hola@profesor.com",
  "hola@alumno.com",
  "editorplantel@prueba.com",
  "profesor310@yopmail.com",
  "asesor310@yopmail.com",
  "alumno310@yopmail.com",
  "coordinador_academico310@yopmail.com",
  "mcornejo@grupoeducare.com",
];

const validarAcceso = (correo) => {
  return correosValidosRegex.test(correo) || correosConAcceso.includes(correo);
};

export default validarAcceso;
