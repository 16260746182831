import React, { useContext, useEffect, useState } from 'react';
import NimbusModal from '../../fragments/NimbusModal';
import GenerarFacturaForm from './fragments/GenerarFacturaForm';
import CancelButton from '../../fragments/Buttons/CancelButton';
import SubmitButton from '../../fragments/Buttons/SubmitButton';
import useValidateForm from '../../hooks/useValidateForm';
import { toast } from 'react-toastify';
import alertConfiguration from '../../utils/alertsUtils';
import useFormSubmit from '../../hooks/useFormSubmit';
import { errorToast } from '../../constructor/umas/ActividadesUtils/FormularioUtils';
import { UserContext } from '../../services/user';
import {
  getInfoFacturacionUsuario,
  getMisCompras,
  postFacturacion,
} from '../../services/api';

const INITIAL_VALUES_FACTURA = {
  compra: '',
  email: '',
  nombreCompleto: '',
  cp: '',
  rfc: '',
  regimenFiscal: '',
  usoFactura: '',
};

const GenerarFacturaModal = ({ isOpen, handleModal, facturas, itemSelectedId, misComprasList }) => {
  const user = useContext(UserContext);
  const [useData, setUseData] = useState(false);
  const [compras, setCompras] = useState([]);
  const [isOpenConfirmar, setIsOpenConfirmar] = useState(false);
  const [datosFacturacion, setDatosFacturacion] = useState([]);

  const {
    handleChange,
    errors,
    values,
    setIsEnableSubmitButton,
    setValues,
    setErrors,
    areRequiredFieldsFulfilled,
  } = useValidateForm(INITIAL_VALUES_FACTURA);

  const [formStatus, handleSubmit] = useFormSubmit(facturaSubmit);

  async function facturaSubmit() {
    try {
      if (Object.values(errors).length === 0) {
        let body = {
          usuario: {
            _id: user.user._id,
          },
          compra: {
            _id: itemSelectedId || values.compra,
          },
          correoElectronico: values.email,
          nombreCompleto: itemSelectedId ? `${user.user.nombres} ${user.user.primerApellido} ${user.user.segundoApellido}` : values.nombreCompleto,
          cp: values.cp,
          rfc: itemSelectedId ? datosFacturacion.rfc : values.rfc,
          regimenFiscal: values.regimenFiscal,
          usoFactura: values.usoFactura,
        };
        const response = await postFacturacion(body);
        if (response?.error?.status === 500) {
          toast.error('La compra ya está registrada.', alertConfiguration);
        } else {
          toast.success(
            'La factura se ha registrado exitosamente.',
            alertConfiguration
          );
        }
        resetData();
      } else {
        setErrors(errors);
        errorToast('Complete todos los campos requeridos.');
      }
    } catch (error) {
      toast.error('Ha ocurrido un error.', alertConfiguration);
      console.log(error);
    }
  }

  const resetData = () => {
    setValues({
      compra: '',
      email: '',
      nombreCompleto: '',
      cp: '',
      rfc: '',
      regimenFiscal: '',
      usoFactura: '',
    });
    handleModal();
    setIsOpenConfirmar(false);
    setUseData(false);
  };

  const handleUseData = () => {
    setUseData(!useData);
    if (!useData) {
      setValues({
        ...values,
        email: user?.user?.correoElectronico,
        nombreCompleto: datosFacturacion?.nombreCompleto,
        cp: datosFacturacion?.cp,
        rfc: datosFacturacion?.rfc,
        regimenFiscal: datosFacturacion?.regimenFiscal,
      });
    } else {
      setValues({
        ...values,
        email: '',
        nombreCompleto: '',
        cp: '',
        rfc: '',
        regimenFiscal: '',
        usoFactura: '',
      });
    }
  };

  const getCompras = () => {
    setCompras(misComprasList.map((compra) => ({
      _id: compra._id,
      nombre: compra._id,
    })));

    if (!itemSelectedId) {
      getMisCompras(user.user._id).then((res) => {
        let comprasList = res.data
        .filter((compra) => {
          let diasTranscurridos = new Date() - new Date(compra.fechaCreacion);
          diasTranscurridos /= 1000 * 60 * 60 * 24;
          return diasTranscurridos <= 30;
        })
        .map((compra) => ({
          _id: compra._id,
          nombre: compra._id,
        }));
        
        comprasList = comprasList.filter((compra) => {
          return !facturas.some((factura) => {
            return factura.compra === compra._id;
          });
        });
        
        setCompras(comprasList);
      });
    }
    getInfoFacturacionUsuario(user.user._id).then((res) => {
      if (res.data !== undefined) setDatosFacturacion(res.data);
    });
  };

  useEffect(() => {
    if (isOpen) getCompras();
  }, [isOpen]);

  useEffect(() => {
    if (!areRequiredFieldsFulfilled()) {
      setIsEnableSubmitButton(false);
    } else {
      setIsEnableSubmitButton(true);
    }
  }, [values]);

  return (
    <>
      <NimbusModal isOpen={isOpen}>
        <div className='h-[60vh] md:h-[70vh] w-[26rem] sm:w-[30rem] md:w-[34rem] lg:h-[75vh] max-h-[60rem]'>
          <div className='px-6 justify-center'>
            <h1 className='text-nimbusDark font-bold text-xl'>
              Generar factura
            </h1>
            <p className='text-gray-400 mt-4 font-medium px-10'>
              Primero debe seleccionar la compra de la cual requiere generar la
              factura, y posteriormente, completar los datos solicitados
            </p>
          </div>
          <GenerarFacturaForm
            itemSelectedId={itemSelectedId}
            factura={values}
            errors={errors}
            handleChange={handleChange}
            setFactura={setValues}
            handleUseData={handleUseData}
            useData={useData}
            compras={compras}
          />
          <div className='flex justify-center gap-5 pb-5'>
            <CancelButton text={'Cancelar'} onClick={() => resetData()} />
            <SubmitButton
              text={'Enviar'}
              onClick={() => setIsOpenConfirmar(true)}
              loadingButton
              loading={formStatus === 'submitting'}
            />
          </div>
        </div>
      </NimbusModal>

      <NimbusModal isOpen={isOpenConfirmar}>
        <h1 className='text-nimbusDark font-bold'>Generar factura</h1>
        <p className='w-96 px-4 sm:px-2'>
          Esta factura solo es posible generarla con los datos del usuario que la esta solicitando
        </p>
        <div className='w-96 px-4 text-center mb-6'>
          <p className='text-nimbusDark font-bold'>
            ¿Está seguro que la información es correcta?
          </p>
        </div>
        <div className='flex justify-center gap-x-4'>
          <CancelButton
            text='Cancelar'
            bgColor='bg-divider'
            onClick={() => setIsOpenConfirmar(false)}
          />
          <SubmitButton
            text='Aceptar'
            onClick={handleSubmit}
          />
        </div>
      </NimbusModal>
    </>
  );
};

export default GenerarFacturaModal;
