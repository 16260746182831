/* Manejan la colección de tareas creadas por un profesor */

import { _delete, _get, _post, _put } from "../../http";

export const getTareasProfesor = (profesorId) =>
  _get(`/tareas/profesor/${profesorId}`);

export const getTareasEliminadasProfesor = (profesorId) =>
  _get(`/tareas/eliminadasProfesor/${profesorId}`);

export const getTareasGrupo = (groupId) => _get(`/tareas/group/${groupId}`);

export const crearTarea = (body) => _post(`/tareas`, body);

export const obtenerDetalle = (tareaId) => _get(`/tareas/tarea/${tareaId}`);

export const actualizarTarea = (tareaId, body) =>
  _put(`/tareas/${tareaId}`, body);

export const deleteTarea = (tareaId) => _put(`/tareas/${tareaId}/eliminar`);

export const recuerarTarea = (tareaId) => _put(`/tareas/${tareaId}/recuperar`);

export const deleteTareaPermanentemente = (tareaId) =>
  _delete(`/tareas/${tareaId}/eliminarTareaPermanentemente`);

export const getTareasGruposProfesores = (body) => 
  _post(`/tareas/obtenerTareasGruposProfesores`, body);

/* Manejan el registro de la calificacion de tareas  */

export const getDetalleCalificacion = (tareaId, alumnoId, grupoId) =>
  _get(`/calificacionTareas/${tareaId}/alumnos/${alumnoId}/grupo/${grupoId}`);

export const getCalificacionesGrupales = (tareaId, grupoId) =>
  _get(`/calificacionTareas/${tareaId}/grupo/${grupoId}`);

export const getTareasResueltas = (alumnoId) =>
  _get(`/calificacionTareas/${alumnoId}/listadoPorAlumno`);

export const crearRegistro = (body) => _post(`/calificacionTareas`, body);

export const calificar = (tareaId, alumnoId, body) =>
  _put(`/calificacionTareas/${tareaId}/alumnos/${alumnoId}`, body);

export const restablecerRegistrosPorTarea = (id, body) =>
  _put(`/restablecerTarea/${id}`, body);

/* CARPETAS */

export const getCarpetasPrimariasTareas = (id) =>
  _get(`/carpetas/obtenerCarpetasPrimariasTareas/${id}`);

export const getCarpetasSecunadariasTareas = (id) =>
  _get(`/carpetas/obtenerCarpetasSecundariasTareas/${id}`);

export const getTareasUsuario = (id) =>
  _get(`/carpetas/obtenerTareasUsuario/${id}`);

export const obtenerInfoCarpetaTareas = (id) =>
  _get(`/carpetas/obtenerInfoCarpetaTarea/${id}`);

export const putTareaCarpeta = (idCarpeta, body) =>
  _put(`/carpetas/putTareasCarpeta/${idCarpeta}`, body);

export const removeTareaCarpeta = (body) =>
  _post(`/carpetas/removeTareaCarpeta`, body);

export const putTareaCarpetaMasiva = (id, body) =>
  _put(`/carpetas/putContenidosCarpetaTarea/${id}`, body);

export const removeTareaCarpetaMasiva = (body) =>
  _post(`/carpetas/removeCarpetaMasivaTarea`, body);
