import { useEffect } from "react";
import ImagenValidada from "../../constructor/umas/Actividades/ActividadesUtils/RecursosValidados/ImagenValidada";
import CabeceraRecorada from "./CabeceraRecortada";

export default function Cabecera({ imagen, texto, tipoImagen, tipoCabecera }) {
  return (
    <>
      {tipoCabecera?.includes("MITAD") ? (
        <CabeceraRecorada
          imagen={imagen}
          texto={texto}
          tipoImagen={tipoImagen}
          tipoCabecera={tipoCabecera}
        />
      ) : (
        <div
          className={`right-[5%] w-[110%] my-6 flex items-center justify-center align-center relative min-h-[11rem] md:min-h-[297px] bg-no-repeat bg-center rounded-lg bg-[#f5f5f5]`}
        >
          <ImagenValidada
            className={`absolute top-0 bottom-0 right-0 left-0 w-full h-full z-0 ${
              tipoImagen === "CORTADA"
                ? "object-cover"
                : tipoImagen === "COMPLETA"
                ? "object-contain"
                : "object-fill"
            }`}
            src={imagen}
          />
          <div
            className="p-2 justify-center visor w-[90%] md:w-[70%] z-[1]"
            dangerouslySetInnerHTML={{ __html: texto }}
          ></div>
        </div>
      )}
    </>
  );
}
