import React, { useContext, useEffect, useState } from 'react';
import MisComprasRow from './MisComprasRow';
import GenerarFacturaModal from '../../Facturacion/GenerarFacturaModal';
import { getFacturasUsuario } from '../../../services/api';
import { UserContext } from '../../../services/user';

const MisComprasTable = ({misComprasList, tipoDeMoneda, abreviatura}) => {
  const user = useContext(UserContext);
  const [openModal, setOpenModal] = useState(false);
  const [itemSelected, setItemSelected] = useState(null);
  const [bills, setBills] = useState([]);

  const handleCreateBill = (itemId) => {
    setItemSelected(itemId);
    setOpenModal(true);
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  }

  const getBills = async () => {
    getFacturasUsuario(user.user._id).then((res) => {
      if (res.data !== undefined) {
        setBills(res.data);
      }
    });
  };

  useEffect(() => {
    getBills();
  }, []); 

  return (
    <div className='overflow-x-auto'>
      <table className='w-full table-auto border-separate'>
        <thead>
          <tr className='text-left font-semibold font-sans text-black'>
            <th></th>
            <th>ID de <br/>la compra</th>
            <th>Fecha de<br/>compra</th>
            <th>
              <div className="flex gap-2 items-center justify-center">
                Total ({tipoDeMoneda[0]})
              </div>
            </th>
            <th>Método de pago</th>
            <th>Estatus de pago</th>
            <th>Estatus de entrega</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {misComprasList.map((compra) => (
            <MisComprasRow key={compra._id} compraInfo={compra} abreviatura={abreviatura} handleCreateBill={handleCreateBill}/>
          ))}
        </tbody>
      </table>

      <GenerarFacturaModal
        itemSelectedId={itemSelected}
        isOpen={openModal}
        handleModal={handleCloseModal}
        facturas={bills}
        misComprasList={misComprasList}
      />
    </div>
  );
};

export default MisComprasTable;
