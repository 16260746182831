import ImagenValidada from "../../constructor/umas/Actividades/ActividadesUtils/RecursosValidados/ImagenValidada";

export default function Indicador({ imagen, texto, tipo, lineOptions }) {
  const parsedOptions = lineOptions && JSON.parse(lineOptions);
  const isReversed = parsedOptions?.orientation === "DERECHA";

  return (
    <div
      className={`my-5 px-2 flex items-center h-min ${
        isReversed && "flex-row-reverse"
      }`}
    >
      {parsedOptions?.height === 5 && (
        <div
          className={`w-1 h-1 p-2  rounded-full bg-[#697D99] ${
            parsedOptions && parsedOptions?.color + "-nimbus"
          }`}
        ></div>
      )}
      <div
        style={{
          backgroundColor:
            parsedOptions?.color &&
            !parsedOptions?.color.includes("#") &&
            parsedOptions.color,
          height: parsedOptions?.height,
        }}
        className={`bg-[#697D99] flex-1 h-3 mx-2 rounded-full ${
          parsedOptions?.color + "-nimbus"
        } `}
      ></div>

      <div className="h-min flex items-center">
        <div className="rounded-lg p-2 md:p-3">
          <ImagenValidada
            className={`w-[60px] md:w-[100px] rounded aspect-square ${
              tipo === "CORTADA"
                ? "object-cover"
                : tipo === "COMPLETA"
                ? "object-contain"
                : "object-fill"
            }`}
            src={imagen}
          />
        </div>
        <div className="grid content-center gap-y-2 flex-1">
          <div className="">
            <p
              className="text-sm md:text-base h-max text-black font-bold break-words"
              dangerouslySetInnerHTML={{ __html: texto }}
            ></p>
          </div>
        </div>
      </div>
    </div>
  );
}
