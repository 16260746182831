import { useState, useEffect } from "react";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrowDownSimple.svg";
import { ReactComponent as ArrowUp } from "../../../assets/icons/arrowUpSimple.svg";
import { replaceTextLinks } from "../../constructor/umas/Actividades/ActividadesUtils/ActividadesUtils";
import ImagenValidada from "../../constructor/umas/Actividades/ActividadesUtils/RecursosValidados/ImagenValidada";

export default function Colapsable({ texto1, texto2, imagen, tipo }) {
  const [dropDown, setDropDown] = useState(false);

  return (
    <div className="min-h-[15rem] visor shadow-[0px_10px_20px_#e6ebf6] overflow-hidden z-[2]  grid w-full justify-items-start gap-x-6 bg-white rounded-md h-min p-6 my-4 relative">
      <ImagenValidada
        src={imagen}
        className={`relative mx-auto md:absolute right-0 top-0 -z-[1] w-[14rem] h-[14rem] md:mt-2 md:mr-2 rounded-lg  ${
          tipo === "CORTADA"
            ? "object-cover"
            : tipo === "COMPLETA"
            ? "object-contain"
            : "object-fill"
        }`}
      />
      <div className="flex gap-x-6 px-3 w-full relative">
        <div
          dangerouslySetInnerHTML={{ __html: replaceTextLinks(texto1) }}
          className="w-full  md:pr-[235px] min-h-[9rem] item¡s-center pt-6 md:pt-12"
        ></div>
      </div>
      {dropDown && (
        <div className="md:pt-20 px-3 w-full">
          <div
            dangerouslySetInnerHTML={{ __html: replaceTextLinks(texto2) }}
          ></div>
        </div>
      )}
      <div
        className="shadow-[inset_0px_5px_10px_#d0d5e7] absolute cursor-pointer h-10 w-14 flex justify-center items-center hover:bg-[#f5f5f5] left-1/2 -translate-y-1/2 bottom-[-20px] rounded-tl-[99px] rounded-tr-[99px] rounded-bl-none rounded-br-none
        "
        onClick={() => setDropDown(!dropDown)}
      >
        <div className="w-0 h-0 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-t-[13px] border-t-nimbusDark rounded-b-lg mt-2 "></div>
      </div>
    </div>
  );
}
