import React from "react";
import {
  replaceTextLinks,
  textBackgroundsOptions,
} from "../../constructor/umas/Actividades/ActividadesUtils/ActividadesUtils";

const Texto = ({ texto, _id, background }) => {
  const foundBackground = textBackgroundsOptions?.find(
    (el) => el.name === background
  );

  return (
    <div
      style={{
        color: foundBackground?.textColor ? foundBackground?.textColor : null,
      }}
      className="my-10"
    >
      {foundBackground?.header && (
        <img
          src={foundBackground?.header}
          className="w-full h-auto relative top-[13px] rounded-tl-md rounded-tr-md"
        />
      )}
      <div
        className={`w-full ${
          foundBackground?.header ? "" : "rounded-tl-md rounded-tr-md"
        } ${
          foundBackground?.footer ? "" : "rounded-bl-md rounded-br-md"
        } ${background}`}
        style={{
          backgroundImage: foundBackground
            ? `url(${foundBackground?.path})`
            : undefined,
          backgroundSize: "cover",
          padding: foundBackground ? "5rem" : 0,
          backgroundSize: "100%",
        }}
      >
        {texto !== "" && (
          <div
            className="p-2 justify-center visor"
            dangerouslySetInnerHTML={{ __html: replaceTextLinks(texto) }}
          ></div>
        )}
      </div>
      {foundBackground?.footer && (
        <img
          src={foundBackground?.footer}
          className="w-full h-auto relative rounded-bl-md rounded-br-md"
        />
      )}
    </div>
  );
};

export default Texto;
